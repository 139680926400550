<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- <c-btn 
            v-show="!disabled && Boolean(popupParam.iimAccidentId)"
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="accidentInfo"
            :mappingType="completeType"
            label="완료" 
            icon="check"
            @beforeAction="completeAccident"
            @btnCallback="completeCallback" />
          <c-btn
            v-if="!disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="accidentInfo"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveAccident"
            @btnCallback="saveCallback"
          />
          <c-btn v-show="!disabled && Boolean(popupParam.iimAccidentId)" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="remove" /> -->
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            required
            :editable="editable"
            :disabled="disabled"
            label="아차사고명"
            name="accidentName"
            v-model="accidentInfo.accidentName">
          </c-text>
        </div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            :disabled="true"
            label="아차사고번호"
            name="accidentNo"
            v-model="accidentInfo.accidentNo">
          </c-text>
        </div>
        <div class="col-xs-7 col-sm-7 col-md-3 col-lg-3 col-xl-3">
          <c-multi-select
            required
            codeGroupCd="IIM_NEAR_KIND_CD"
            :editable="editable"
            :disabled="disabled"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="accidentKindCd"
            label="아차사고종류"
            v-model="accidentInfo.accidentKindCd"
          >
          </c-multi-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            required
            :editable="editable"
            type="datetime"
            :disabled="disabled"
            :minuteStep="10"
            label="발생가능일시"
            name="occurrenceDate"
            v-model="accidentInfo.occurrenceDate"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="발생가능장소"
            name="occurrenceLocation"
            v-model="accidentInfo.occurrenceLocation">
          </c-text>
        </div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3 col-xl-3">
          <c-plant
            :required="true"
            :disabled="disabled"
            :editable="editable"
            type="edit"
            name="plantCd"
            v-model="accidentInfo.plantCd" />
        </div>
        <div class="col-xs-7 col-sm-7 col-md-3 col-lg-3 col-xl-3">
          <c-process
            :required="true"
            :editable="editable"
            :disabled="disabled"
            label="공정"
            name="processCd"
            v-model="accidentInfo.processCd">
          </c-process>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-field
            :editable="editable"
            :disabled="disabled"
            :data="accidentInfo"
            label="보고자"
            type="dept_user"
            name="reportUserId"
            v-model="accidentInfo.reportUserId">
          </c-field>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :editable="editable"
            :disabled="disabled"
            default="today"
            type="datetime"
            label="보고일시"
            :minuteStep="10"
            name="reportDate"
            v-model="accidentInfo.reportDate"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :editable="editable"
            :disabled="disabled"
            label="발생부서"
            name="occurrenceDeptCd"
            v-model="accidentInfo.occurrenceDeptCd">
          </c-dept>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-radio
            :editable="editable"
            :disabled="disabled"
            :comboItems="weatherItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="weather"
            label="날씨"
            v-model="accidentInfo.weather"
          ></c-radio>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :disabled="disabled"
            type="edit"
            codeGroupCd="IIM_GRADE_CD"
            itemText="codeName"
            itemValue="code"
            name="accidentGradeCd"
            label="아차사고등급"
            v-model="accidentInfo.accidentGradeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="6"
            label="긴급조치사항"
            name="emergencyMeasures"
            v-model="accidentInfo.emergencyMeasures">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="6"
            label="아차사고사항개요"
            name="overviewAccidentSituation"
            v-model="accidentInfo.overviewAccidentSituation">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <c-card title="사고발생시 상태" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-checkbox 
            :editable="editable"
            :disabled="disabled"
            :inline="false"
            :isArray="false"
            codeGroupCd="IIM_NEARMISS_DISCERN"
            itemText="codeName"
            itemValue="code"
            label="인지기능"
            name="iimNearmissDiscern"
            v-model="accidentInfo.iimNearmissDiscern">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-checkbox 
            :editable="editable"
            :disabled="disabled"
            :inline="false"
            :isArray="false"
            codeGroupCd="IIM_NEARMISS_FUNCTION"
            itemText="codeName"
            itemValue="code"
            label="사고기능"
            name="iimNearmissFunction"
            v-model="accidentInfo.iimNearmissFunction">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-checkbox 
            :editable="editable"
            :disabled="disabled"
            :inline="false"
            :isArray="false"
            codeGroupCd="IIM_NEARMISS_FEEL"
            itemText="codeName"
            itemValue="code"
            label="감정"
            name="iimNearmissFeel"
            v-model="accidentInfo.iimNearmissFeel">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-checkbox 
            :editable="editable"
            :disabled="disabled"
            :inline="false"
            :isArray="false"
            codeGroupCd="IIM_NEARMISS_EQUIP"
            itemText="codeName"
            itemValue="code"
            label="설비요인"
            name="iimNearmissEquip"
            v-model="accidentInfo.iimNearmissEquip">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-checkbox 
            :editable="editable"
            :disabled="disabled"
            :inline="false"
            :isArray="false"
            codeGroupCd="IIM_NEARMISS_MANAGE"
            itemText="codeName"
            itemValue="code"
            label="관리요인"
            name="iimNearmissManage"
            v-model="accidentInfo.iimNearmissManage">
          </c-checkbox>
        </div>
      </template>
    </c-card>
    <c-card title="발생 가능형태" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-select
            :editable="editable"
            :disabled="disabled"
            :comboItems="occFirstItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="occurrenceModeLargeCd"
            label="대분류"
            v-model="accidentInfo.occurrenceModeLargeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-select
            :editable="editable"
            :disabled="disabled"
            :comboItems="occSecItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="occurrenceModeSmallCd"
            label="중분류"
            v-model="accidentInfo.occurrenceModeSmallCd"
          ></c-select>
        </div>
      </template>
    </c-card>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-card title="위험성평가">
          <template slot="card-detail">
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <c-text
                :editable="editable"
                :disabled="disabled"
                label="빈도"
                type="number"
                name="frequencyRisk"
                v-model="accidentInfo.frequencyRisk"
                @dataChange="dataChange">
              </c-text>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <c-text
                :editable="editable"
                :disabled="disabled"
                label="강도"
                type="number"
                name="strengthRisk"
                v-model="accidentInfo.strengthRisk"
                @dataChange="dataChange">
              </c-text>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <c-text
                :editable="editable"
                :disabled="true"
                label="위험도"
                type="number"
                name="totalRisk"
                v-model="accidentInfo.totalRisk">
              </c-text>
            </div>
          </template>
        </c-card>
      </div>
    <c-card title="포상금">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label=""
            suffix="원"
            type="number"
            name="bounty"
            v-model="accidentInfo.bounty">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-card title="사고발생 가능 원인" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="직접원인"
            name="directCause"
            v-model="accidentInfo.directCause">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="간접원인"
            name="indirectCause"
            v-model="accidentInfo.indirectCause">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="기인물"
            name="openning"
            v-model="accidentInfo.openning">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="가해물"
            name="injurious"
            v-model="accidentInfo.injurious">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-card title="아차사고 관련 사진" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-upload 
            :attachInfo="attachInfo1"
            label="원거리"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-upload 
            :attachInfo="attachInfo2"
            label="근거리"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-card title="검토정보" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-field
            :editable="editable"
            :disabled="disabled"
            label="검토자"
            name="examineUserId"
            v-model="accidentInfo.examineUserId">
          </c-field>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :editable="editable"
            :disabled="disabled"
            default="today"
            label="검토일"
            name="examineDate"
            v-model="accidentInfo.examineDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-textarea
            :disabled="disabled"
            :editable="editable"
            label="검토의견"
            :rows="4"
            name="examineOpinion"
            v-model="accidentInfo.examineOpinion">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <!-- <c-btn 
            :flat="true"
            v-show="!disabled && Boolean(popupParam.iimAccidentId)"
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="accidentInfo"
            :mappingType="completeType"
            label="완료" 
            icon="check"
            @beforeAction="completeAccident"
            @btnCallback="completeCallback" /> -->
          <c-btn
            :flat="true"
            v-if="!disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="accidentInfo"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveAccident"
            @btnCallback="saveCallback"
          />
          <!-- <c-btn :flat="true" v-show="!disabled && Boolean(popupParam.iimAccidentId)" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="remove" /> -->
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </q-form>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'near-register-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
      }),
    },
    attachInfo1: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'NEAR_FIRST_PICTURE',
        taskKey: '',
      }),
    },
    attachInfo2: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'NEAR_SECOND_PICTURE',
        taskKey: '',
      }),
    },
  },
  data() {
    return {
      accidentInfo: {
        iimAccidentId: '',
        accidentTypeCd: '',
        plantCd: '',
        accidentNo: '',
        accidentName: '',
        accidentStatusCd: '',
        reportUserId: '',
        reportDate: '',
        accidentKindCd: '',
        accidentGradeCd: null,
        occurrenceDate: '',
        occurrenceDeptCd: '',
        occurrenceLocation: '',
        weather: '',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        occurrenceModeLargeCd: null,
        occurrenceModeSmallCd: null,
        humanDamage: '',
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        materialDamageHistory: '',
        humanDamageHistory: '',
        accidentVictimModelList: [],
        damageClassCd: null,
        woundCd: null,
        iimNearmissDiscern: null,
        iimNearmissFunction: null,
        iimNearmissFeel: null,
        iimNearmissEquip: null,
        iimNearmissManage: null,
        frequencyRisk: '',
        strengthRisk: '',
        totalRisk: '',
        ramRiskAssessmentPlanId: '',
        bounty: 0,
      },
      occFirstItems: [],
      occSecItems: [],
      weatherItems: [
        { code: 'WC000001', codeName: '맑음' },
        { code: 'WC000002', codeName: '흐림' },
        { code: 'WC000003', codeName: '비' },
        { code: 'WC000004', codeName: '눈' },
      ],
      humanDamageItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '비해당' },
      ],
      editable: true,
      searchUrl: '',
      grid: {
        columns: [
          {
            name: 'victimName',
            field: 'victimName',
            label: '이름',
            style: 'width:80px',
            align: 'center',
            sortable: true
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            style: 'width:80px',
            align: 'center',
            sortable: true
          },
          {
            name: 'career',
            field: 'career',
            label: '직급',
            style: 'width:60px',
            align: 'center',
            sortable: true
          },
          {
            name: 'insideOutsideTypeCd',
            field: 'insideOutsideTypeCd',
            label: '내/외부',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'Y', codeName: '내부' },
              { code: 'N', codeName: '외부' },
            ],
            sortable: false,
          },
          {
            name: 'degreeDamage',
            field: 'degreeDamage',
            label: '피해상해정도',
            style: 'width:120px',
            type: "textarea",
            align: 'center',
            sortable: true
          },
          {
            name: 'expectTreatmentDate',
            field: 'expectTreatmentDate',
            label: '예상치료기간',
            type: "date",
            range: true,
            style: 'width:150px',
            align: 'center',
            sortable: true
          },
          {
            name: 'actionMatters',
            field: 'actionMatters',
            label: '조치사항',
            style: 'width:200px',
            type: "textarea",
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '300px'
      },
      saveUrl: 'transactionConfig.sop.iim.accident.near.insert.url',
      completeUrl: 'transactionConfig.sop.iim.accident.near.insert.url',
      saveType: 'POST',
      completeType: 'POST',
      isSave: false,
      isComplete: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      updateMode: false
    };
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.accidentInfo.accidentStatusCd) && this.accidentInfo.accidentStatusCd !== 'ISNC000001'
    }
  },
  watch: {
    'accidentInfo.occurrenceModeLargeCd'() {
      if (!this.accidentInfo.occurrenceModeLargeCd) return;
      this.setSecondItems(this.accidentInfo.occurrenceModeLargeCd)
    },
    'accidentInfo'() {
      this.$emit('transInfo', this.accidentInfo);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.near.get.url;
      this.insertUrl = transactionConfig.sop.iim.accident.near.insert.url;
      this.updateUrl = transactionConfig.sop.iim.accident.near.update.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.near.delete.url;
      this.completeUrl = transactionConfig.sop.iim.accident.near.complete.url;
      // list setting
      this.getDetail();
      this.setFirstItems();
    },
    getDetail() {
      if (this.popupParam.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.accidentInfo = _result.data;
          this.$_.forEach(this.accidentInfo.accidentVictimModelList, _item => {
            if (_item.expectTreatmentDate) {
              _item.expectTreatmentDate = _item.expectTreatmentDate.split(',')
            }
          })
          this.updateMode = true;
        },);
      } else {
        this.accidentInfo.overviewAccidentSituation =
          '- ' +
          '누가 : \n' + // 누가
          '- ' +
          '누구에게 : \n' + // 누구에게
          '- ' +
          '언제 : \n' + // 언제
          '- ' +
          '어디서 : \n' + // 어디서
          '- ' +
          '무엇을 : \n' + // 무엇을
          '- ' +
          '어떻게 : \n' + // 어떻게
          '- ' +
          '왜 : '
      }
    },
    setFirstItems() {
      this.$http.url = selectConfig.sop.iim.occurForm.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
        occurLevel: '1',
      }
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          this.occFirstItems.splice(0, 0, {
            code: _item.iimOccurMasterId,
            codeName: _item.occurName,
          })
        })
      },);
    },
    setSecondItems(iimOccurMasterId) {
      this.$http.url = selectConfig.sop.iim.occurForm.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        iimUpOccurMasterId: iimOccurMasterId,
        useFlag: 'Y',
        occurLevel: '2',
      }
      this.$http.request((_result) => {
        this.occSecItems = [];
        this.$_.forEach(_result.data, _item => {
          this.occSecItems.splice(0, 0, {
            code: _item.iimOccurMasterId,
            codeName: _item.occurName,
          })
        })
      },);
    },
    saveAccident() {
      if (this.popupParam.iimAccidentId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.accidentInfo.accidentStatusCd = 'ISNC000001'
              this.$_.forEach(this.accidentInfo.accidentVictimModelList, _item => {
                if (_item.expectTreatmentDate) _item.expectTreatmentDate = _item.expectTreatmentDate.join();
              })
              this.accidentInfo.regUserId = this.$store.getters.user.userId
              this.accidentInfo.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      
      if (!this.popupParam.iimAccidentId) {
        this.$emit('changeStatus', result.data)
        this.popupParam.iimAccidentId = result.data;
      }
      this.getDetail();

    },
    add() {
      this.popupOptions.title = '사고자 검색'; // 사고자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.accidentInfo.accidentVictimModelList, {
            victimUserId: _item.userId,
          });
          if (index === -1) {
            this.accidentInfo.accidentVictimModelList.splice(0, 0, {
              iimAccidentId: this.accidentInfo.iimAccidentId,
              victimId: uuidv4(),
              victimUserId: _item.userId,
              victimName: _item.userName,
              birthDate: _item.birthDate,
              age: '',
              career: _item.spotName,
              degreeDamage: '',
              expectTreatmentDate: '',
              actionMatters: '',
              insideOutsideTypeCd: null,
            })
          }
        })
      }
    },
    completeAccident() {
      this.completeType = 'PUT';
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '완료 하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accidentInfo.accidentStatusCd = 'ISNC000002'
          this.accidentInfo.regUserId = this.$store.getters.user.userId
          this.accidentInfo.chgUserId = this.$store.getters.user.userId
          
          this.isComplete = !this.isComplete;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },/* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.iimAccidentId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup', 'REMOVE');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    dataChange() {
      this.accidentInfo.totalRisk = this.accidentInfo.frequencyRisk * this.accidentInfo.strengthRisk
    },
  }
};
</script>
